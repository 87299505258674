import { DsxSensorTypes, RfSensorTypes } from '@/store/modules/rf_sensors/types'

export default (hasCompass = false) => [
  {
    label: 'Name',
    sensors: 'all',
    required: true,
    key: 'name',
    fieldType: 'v-text-field',
    doNotReset: true
  },
  {
    label: 'Radar Type',
    sensors: ['radar'],
    items: [
      { value: 'radar_zero', text: 'RadarZero' },
      { value: 'radar_one', text: 'RadarOne' },
      { value: 'radar_two', text: 'RadarTwo' }
    ],
    value: 'radar_zero',
    key: 'radar_type',
    fieldType: 'v-select',
    doNotReset: true
  },
  {
    label: 'Serial Number',
    sensors: 'all',
    key: 'serial_number',
    fieldType: 'v-text-field',
    doNotReset: true
  },
  {
    label: 'Latitude',
    disabled: hasCompass,
    sensors: ['discovair'],
    key: 'latitude',
    fieldType: 'v-text-field',
    editOnly: ['compass'],
    doNotReset: true
  },
  {
    label: 'Longitude',
    disabled: hasCompass,
    sensors: ['discovair'],
    key: 'longitude',
    fieldType: 'v-text-field',
    editOnly: ['compass'],
    doNotReset: true
  },
  {
    label: 'Altitude',
    disabled: hasCompass,
    sensors: ['camera'],
    key: 'altitude',
    value: 0,
    fieldType: 'v-text-field'
  },
  {
    label: 'Height',
    sensors: ['radar'],
    key: 'height',
    value: 0,
    fieldType: 'v-text-field'
  },
  {
    label: 'Tilt',
    sensors: ['radar'],
    key: 'tilt',
    value: 12,
    fieldType: 'v-text-field',
    advanced: true
  },
  {
    label: 'Roll',
    sensors: ['radar'],
    key: 'roll',
    value: 0,
    fieldType: 'v-text-field',
    advanced: true
  },
  {
    label: 'Display nearby detections',
    sensors: ['dronesentryx'],
    key: 'show_720_detection',
    value: true,
    fieldType: 'v-switch',
    advanced: true
  },
  {
    label: 'IMU Tilt',
    sensors: ['radar'],
    key: 'imu_tilt',
    value: 0,
    fieldType: 'v-text-field',
    advanced: true,
    readOnly: true
  },
  {
    label: 'IMU Roll',
    sensors: ['radar'],
    key: 'imu_roll',
    value: 0,
    fieldType: 'v-text-field',
    advanced: true,
    readOnly: true
  },
  {
    label: 'Azimuth Minimum (Search)',
    sensors: ['radar'],
    key: 'radar_az_min_search',
    value: -48,
    type: 'number',
    fieldType: 'v-text-field',
    advanced: true
  },
  {
    label: 'Azimuth Maximum (Search)',
    sensors: ['radar'],
    key: 'radar_az_max_search',
    value: 48,
    type: 'number',
    fieldType: 'v-text-field',
    advanced: true
  },
  {
    label: 'Azimuth Step Size (Search)',
    sensors: ['radar'],
    key: 'radar_az_step',
    value: 4,
    type: 'number',
    fieldType: 'v-text-field',
    advanced: true
  },
  {
    label: 'Elevation Minimum (Search)',
    sensors: ['radar'],
    key: 'radar_el_min_search',
    value: -16,
    type: 'number',
    fieldType: 'v-text-field',
    advanced: true
  },
  {
    label: 'Elevation Maximum (Search)',
    sensors: ['radar'],
    key: 'radar_el_max_search',
    value: 32,
    type: 'number',
    fieldType: 'v-text-field',
    advanced: true
  },
  {
    label: 'Elevation Step Size',
    sensors: ['radar'],
    key: 'radar_el_step',
    value: 12,
    type: 'number',
    fieldType: 'v-text-field',
    advanced: true
  },

  {
    label: 'Azimuth Minimum (Track)',
    sensors: ['radar'],
    key: 'radar_az_min_track',
    value: -60,
    type: 'number',
    fieldType: 'v-text-field',
    advanced: true
  },
  {
    label: 'Azimuth Maximum (Track)',
    sensors: ['radar'],
    key: 'radar_az_max_track',
    value: 60,
    type: 'number',
    fieldType: 'v-text-field',
    advanced: true
  },
  {
    label: 'Elevation Mininum (Track)',
    sensors: ['radar'],
    key: 'radar_el_min_track',
    value: -28,
    type: 'number',
    fieldType: 'v-text-field',
    advanced: true
  },
  {
    label: 'Elevation Maximum (Track)',
    sensors: ['radar'],
    key: 'radar_el_max_track',
    value: 40,
    type: 'number',
    fieldType: 'v-text-field',
    advanced: true
  },
  {
    label: 'Min FOV Angle',
    sensors: ['camera'],
    key: 'min_fov_angle',
    value: 2.3,
    type: 'number',
    fieldType: 'v-text-field'
  },
  {
    label: 'Max FOV Angle',
    sensors: ['camera'],
    key: 'max_fov_angle',
    value: 63.7,
    type: 'number',
    fieldType: 'v-text-field'
  },
  {
    label: 'Field of View',
    sensors: ['camera'],
    key: 'field_of_view',
    value: 360,
    type: 'number',
    fieldType: 'v-text-field'
  },
  {
    label: 'Min Tilt Angle',
    sensors: ['camera'],
    key: 'min_tilt_angle',
    value: -55,
    type: 'number',
    fieldType: 'v-text-field'
  },
  {
    label: 'Max Tilt Angle',
    sensors: ['camera'],
    key: 'max_tilt_angle',
    value: 90,
    type: 'number',
    fieldType: 'v-text-field'
  },
  {
    label: 'Reach',
    sensors: ['rf'],
    key: 'reach',
    fieldType: 'v-slider',
    thumbLabel: true,
    value: 8000
  },
  {
    label: 'Discovair Reach',
    sensors: ['discovair'],
    key: 'reach',
    fieldType: 'v-slider',
    thumbLabel: true,
    value: 500
  },
  {
    label: 'RF Type',
    sensors: ['rf'],
    items: RfSensorTypes,
    value: 'rf_two',
    key: 'model',
    fieldType: 'v-select',
    doNotReset: true
  },
  {
    label: 'DroneSentry-X Reach',
    sensors: ['dronesentryx'],
    key: 'reach',
    fieldType: 'v-slider',
    thumbLabel: true,
    value: 3000
  },
  {
    label: 'DroneSentry-X Jam Reach',
    sensors: ['dronesentryx'],
    key: 'reach_jamming',
    fieldType: 'v-slider',
    thumbLabel: true,
    value: 500
  },
  {
    label: 'DroneSentry-X Type',
    sensors: ['dronesentryx'],
    items: DsxSensorTypes,
    value: 'dsx_direct',
    key: 'model',
    fieldType: 'v-select',
    doNotReset: true
  },
  {
    label: 'Reach',
    sensors: ['cannon'],
    key: 'reach',
    fieldType: 'v-slider',
    thumbLabel: true,
    value: 2000,
    max: 5000,
    step: 100,
    min: 0
  },
  {
    label: 'Direction',
    disabled: hasCompass,
    sensors: ['rf', 'radar', 'cannon', 'camera', 'discovair'],
    key: 'direction',
    fieldType: 'v-slider',
    thumbLabel: true,
    value: 90,
    max: 360,
    min: 0
  },
  // {
  //   label: 'Altitude AGL',
  //   sensors: ['rf', 'radar', 'cannon'],
  //   key: 'agl',
  //   fieldType: 'v-slider',
  //   thumbLabel: true,
  //   value: 10,
  //   max: 900,
  //   min: 0
  // },
  {
    label: 'Direction',
    sensors: ['rf', 'radar', 'cannon', 'camera'],
    key: 'direction',
    fieldType: 'v-text-field',
    value: 0
  },
  {
    label: 'Frequency Channel',
    sensors: ['radar'],
    value: 0,
    items: [
      {
        value: 0,
        text: 'Channel 1'
      },
      {
        value: 1,
        text: 'Channel 2'
      },
      {
        value: 2,
        text: 'Channel 3'
      }
    ],
    fieldType: 'v-select',
    key: 'channel',
    attribute: 'channel'
  },
  {
    label: 'Time Channel Mitigation (TCM)',
    sensors: ['radar'],
    value: -1,
    items: [
      {
        value: -1,
        text: 'TCM Disabled'
      },
      {
        value: 0,
        text: 'Time Channel 1'
      },
      {
        value: 1,
        text: 'Time Channel 2'
      },
      {
        value: 2,
        text: 'Time Channel 3'
      },
      {
        value: 3,
        text: 'Time Channel 4'
      }
    ],
    fieldType: 'v-select',
    key: 'tcm_num',
    attribute: 'tcm_num'
  },
  {
    label: 'Type',
    sensors: ['cannon'],
    key: 'cannon_type',
    value: '5B-MK2',
    doNotReset: true
  },
  {
    label: 'Shutoff Time',
    sensors: ['cannon'],
    key: 'shutoff_time',
    value: '300'
  },
  {
    label: 'Show Sectors on Map',
    sensors: ['rf'],
    fieldType: 'v-switch',
    key: 'show_sectors',
    type: Boolean,
    value: false
  },
  {
    label: 'Show Sector as Lines',
    sensors: ['rf'],
    key: 'show_sector_as_line',
    fieldType: 'v-switch',
    type: Boolean,
    value: true
  },
  {
    label: 'Show Outside Detections',
    sensors: ['rf'],
    key: 'show_outside_detections',
    fieldType: 'v-switch',
    type: Boolean,
    value: true
  },
  {
    label: 'Show Sectors on Map',
    sensors: ['discovair'],
    fieldType: 'v-switch',
    key: 'show_sectors',
    type: Boolean,
    value: true
  },
  {
    label: 'Show Sector as Lines',
    sensors: ['discovair'],
    key: 'show_sector_as_line',
    fieldType: 'v-switch',
    type: Boolean,
    value: false
  },
  {
    label: 'Show Outside Detections',
    sensors: ['discovair'],
    key: 'show_outside_detections',
    fieldType: 'v-switch',
    type: Boolean,
    value: false
  },
  {
    label: 'Auto Jamming',
    sensors: ['cannon'],
    key: 'auto_jam',
    fieldType: 'v-switch',
    value: false,
    type: Boolean
  },
  {
    label: 'Auto Jam Association',
    sensors: ['cannon'],
    key: 'auto_jam_association_mode',
    items: ['RF Intersection Detection', 'RF Confirmed Radar Targets'],
    value: 'rf',
    fieldType: 'v-select'
  },
  {
    label: 'IP Address',
    sensors: ['cannon', 'radar', 'rf', 'camera'],
    key: 'ip',
    fieldType: 'v-text-field'
  },
  {
    label: 'Netmask',
    sensors: ['radar'],
    key: 'netmask',
    fieldType: 'v-text-field'
  },
  {
    label: 'MAC Address',
    sensors: ['camera'],
    key: 'mac_address',
    fieldType: 'v-text-field'
  },
  {
    label: 'Video URL',
    sensors: ['camera'],
    key: 'video_url',
    fieldType: 'v-text-field'
  },
  {
    label: 'Username',
    sensors: ['camera'],
    key: 'username',
    fieldType: 'v-text-field',
    value: 'service'
  },
  {
    label: 'Password',
    sensors: ['camera'],
    key: 'password',
    fieldType: 'v-text-field'
  },
  {
    label: 'Port',
    sensors: ['camera'],
    key: 'port',
    fieldType: 'v-text-field'
  },
  {
    label: 'Auto Recording',
    sensors: ['camera'],
    key: 'auto_record',
    fieldType: 'v-switch',
    type: Boolean,
    value: false
  },
  {
    label: 'Autofocus',
    sensors: ['camera'],
    key: 'tracking_auto_focus',
    fieldType: 'v-switch',
    type: Boolean,
    value: true
  },
  {
    label: 'Manual Focus',
    sensors: ['camera'],
    key: 'tracking_manual_focus',
    fieldType: 'v-switch',
    type: Boolean,
    value: true
  },
  {
    label: 'DroneOptID Probability Threshold',
    sensors: ['camera'],
    key: 'droneoptid_prob_threshold',
    fieldType: 'v-slider',
    thumbLabel: true,
    value: 30,
    min: 0,
    max: 1
  },
  {
    label: 'Targeted Size for Initial Zoom',
    sensors: ['camera'],
    key: 'tracking_slew_hfov_percent',
    fieldType: 'v-slider',
    thumbLabel: true,
    value: 3,
    min: 0,
    max: 0.25
  },
  {
    label: 'Slewing Delay',
    sensors: ['camera'],
    key: 'tracking_slew_retry_delay',
    fieldType: 'v-slider',
    thumbLabel: true,
    value: 0.5,
    min: 0,
    max: 60
  },
  {
    label: 'Max. Recording Duration',
    sensors: ['camera'],
    key: 'recording_max_duration',
    fieldType: 'v-slider',
    thumbLabel: true,
    value: 10,
    min: 1,
    max: 30
  },
  {
    label: 'Control Loop Speed',
    sensors: ['camera'],
    key: 'tracking_control_loop_speed',
    fieldType: 'v-select',
    items: [
      { value: -1, text: 'Slower' },
      { value: 0, text: 'Normal' },
      { value: 1, text: 'Faster' }
    ],
    value: 0
  },
  {
    label: 'Auto Tracking',
    sensors: ['camera'],
    key: 'auto_track',
    fieldType: 'v-select',
    items: [
      { value: 0, text: 'Disabled' },
      { value: 1, text: 'Any Radar' },
      { value: 2, text: 'Radar Confirmed' },
      { value: 3, text: 'RF-Radar Association' },
      { value: 4, text: 'RF Intersection' },
      { value: 5, text: 'RF Only' }
    ],
    value: 0
  },
  {
    label: 'Direction Offset',
    sensors: ['rf', 'radar', 'cannon', 'discovair', 'camera', 'dronesentryx'],
    key: 'direction_offset',
    value: 0,
    type: Number
  },
  {
    label: 'Camera Type',
    sensors: ['camera'],
    key: 'camera_type',
    items: ['DroneOpt1', 'DroneOpt2'],
    value: 'droneopt1',
    fieldType: 'v-select'
  },
  {
    label: 'Streaming Bitrate',
    sensors: ['camera'],
    key: 'streaming_bitrate',
    thumbLabel: true,
    value: 1
  },
  {
    label: 'Always Stream',
    sensors: ['camera'],
    key: 'always_stream',
    fieldType: 'v-switch',
    type: Boolean,
    value: true
  },
  {
    label: 'Detector Identifier',
    sensors: ['discovair'],
    key: 'detector_identifier',
    fieldType: 'v-text-field',
    doNotReset: true
  },
  // not used in the form but without this the active field would be default to false in the backend so required when sending
  // PATCH requests for the rf sensors
  {
    label: 'Activate Rf Sensor',
    sensors: ['rf', 'dronesentryx'],
    key: 'active',
    fieldType: 'v-text-field',
    doNotReset: true
  },
  {
    label: 'Slow Moving Target Masking',
    sensors: ['radar'],
    key: 'mask_clutter_width',
    type: Number,
    value: 3
  },
  {
    label: 'Mask by Min/Max RCS (dBsm)',
    sensors: ['radar'],
    key: 'min_max_rcs',
    type: Array,
    value: [-50, -5]
  },
  {
    label: 'Reach',
    sensors: ['radar'],
    key: 'reach',
    fieldType: 'd-slider',
    type: Number,
    value: 1000
  }
]
