<template>
  <d-form
    v-model="valid"
    @onCancel="
      $router.back()
      $emit('onCancel')
    "
    includeCancel
    @onSubmit="$emit('onSubmit')"
    v-bind="{ error, status }"
    @advancedExpanded="expanded = true"
    dataCy="rf-sensor-form"
  >
    <template v-slot:general>
      <v-select
        v-if="showInstallationSelector"
        label="Installation"
        :items="sentries"
        item-text="name"
        item-value="id"
        ref="installationSelect"
        v-model="sensor.sentry_id"
        :disabled="!authorizeEdit"
        @change="$_updatedSentry()"
        :error="selectInstallationError"
        :suffix="selectInstallationSuffix"
      />
      <v-text-field
        label="Name"
        ref="nameField"
        autofocus
        v-model.trim="sensor.name"
        :disabled="!authorizeEdit"
        :rules="[rules.required]"
      />
      <div data-cy="rf-type">
        <v-select
          data-cy="rf-type-select"
          label="RF Type"
          :items="currentSensorTypes"
          v-model="sensor.model"
          @change="RFTypeChanged"
          :disabled="!authorizeEdit"
        />
      </div>
      <v-text-field
        label="Serial Number"
        v-model.trim="sensor.serial_number"
        v-if="!activePlan"
        :disabled="!authorizeEdit"
        :rules="[rules.required, rules.serialNumberLength]"
        :error="
          sensor.serial_number
            ? sensor.serial_number.length != 13
              ? true
              : false
            : false
        "
      />
      <d-param-slider
        v-if="!activePlan && !['rf_patrol'].includes(sensor.model)"
        label="Direction Offset"
        v-model.number="sensor.direction_offset"
        max="359"
        :unit="getDegreeUnit"
        :disabled="!authorizeEdit"
        :absolute-value="$_absoluteDirection"
      />
      <v-layout d-flex flex-column>
        <v-flex grow>
          <d-param-slider
            label="Display Range"
            v-model="sensor.reach"
            :min="reachParams.min"
            :max="reachParams.max"
            :step="reachParams.step"
            :unit="convertUnitIfImperial(userSettings, 'm')"
            :disabled="!authorizeEdit"
          />
        </v-flex>
        <v-item-group class="reachPreset">
          <v-item>
            <v-chip
              slot-scope="{ toggle }"
              :selected="
                sensor.reach ===
                  convertValIfImperial(userSettings, reachParams.presets.urban, 'm')
              "
              @click="
                () => {
                  toggle()
                  sensor.reach = convertValIfImperial(
                    userSettings,
                    reachParams.presets.urban,
                    'm'
                  )
                }
              "
              >Urban</v-chip
            >
          </v-item>
          <v-item>
            <v-chip
              slot-scope="{ toggle }"
              :selected="
                sensor.reach ===
                  convertValIfImperial(userSettings, reachParams.presets.rural, 'm')
              "
              @click="
                () => {
                  toggle()
                  sensor.reach = convertValIfImperial(
                    userSettings,
                    reachParams.presets.rural,
                    'm'
                  )
                }
              "
              >Rural</v-chip
            >
          </v-item>
        </v-item-group>
      </v-layout>

      <d-param-slider
        v-if="activePlan && !['rf_zero', 'rf_patrol'].includes(sensor.model)"
        label="Direction"
        v-model.number="sensor.direction"
        :unit="getDegreeUnit"
        :disabled="!authorizeEdit"
        max="359.9"
        step="0.1"
      />
    </template>
    <template v-slot:advanced v-if="!activePlan">
      <div v-if="showDetectionVisualisation">
        <div>
          <div class="grey--text mb-3">Detection Visualisation</div>
          <v-tooltip
            v-if="sensor.model === 'rf_two'"
            right
            max-width="430"
            color="sdark"
          >
            <template v-slot:activator="{ on }">
              <div v-on="on" class="outside-detections-switch">
                <div>
                  <v-switch
                    v-model="sensor.show_outside_detections"
                    type="Boolean"
                    color="primary"
                    style="display: block"
                    class="my-0 mx-0 px-0 py-0"
                    :disabled="!authorizeEdit"
                  />
                </div>

                <div class="preview-wrapper">
                  <div>Show outside detections</div>
                  <div
                    :class="
                      `preview-button-text grey--text ${
                        previewingDetection ? 'disabled-preview' : ''
                      }`
                    "
                    @click="previewOutsideDetection"
                  >
                    Preview
                  </div>
                </div>
              </div>
            </template>
            <span>
              Show indicator when a detection is made outside of sensor field of
              view
            </span>
          </v-tooltip>

          <DetectionVisualization
            :shown="expanded"
            :sensor="sensor"
            @onChange="detectionVisualChanged"
            :sector="sensor.show_sectors"
            :showOutsideDetection="previewingDetection"
            :line="sensor.show_sector_as_line"
          />
        </div>
      </div>
    </template>
  </d-form>
</template>

<script>
import { FormMixin, SensorMixin } from '@/components/Mixins'
import { RfSensorTypes } from '@/store/modules/rf_sensors/types'
import { mapGetters } from 'vuex'
import {
  convertValIfImperial,
  convertUnitIfImperial
} from '@/store/utils/index'
import DetectionVisualization from '@/components/Forms/DetectionVisualisation'

const props = {
  sensor: {
    type: Object
  },
  sentries: {
    type: Array,
    default: () => []
  },
  sensorTypes: {
    type: Array,
    default: () => RfSensorTypes
  },
  authorizeEdit: {
    type: Boolean,
    default: true
  },
  showInstallationSelector: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'rf-form',
  mixins: [FormMixin, SensorMixin],
  data: () => ({
    valid: false,
    expanded: false,
    previewingDetection: false,
    selectInstallationError: true,
    selectInstallationSuffix: 'Please select',
  }),
  props,
  components: {
    DetectionVisualization
  },
  computed: {
    ...mapGetters('plans', ['activePlan']),
    ...mapGetters('sites', ['activeSite']),
    ...mapGetters('users', ['getDegreeUnit', 'user']),
    userSettings() {
      return this.user.settings
    },
    currentSensorTypes() {
      return this.sensorTypes
    },
    showDetectionVisualisation() {
      return !['rf_patrol', 'rf_zero'].includes(this.sensor.model)
    },
    reachParams() {
      let reach
      switch (this.sensor.model) {
        case 'rf_zero':
          reach = {
            reach: this.convertValIfImperial(this.userSettings, 4000, 'm'),
            min: this.convertValIfImperial(this.userSettings, 500, 'm'),
            max: this.convertValIfImperial(this.userSettings, 5000, 'm'),
            step: this.convertValIfImperial(this.userSettings, 500, 'm'),
            presets: {
              urban: this.convertValIfImperial(this.userSettings, 1000, 'm'),
              rural: this.convertValIfImperial(this.userSettings, 4000, 'm')
            }
          }
          break
        case 'rf_one':
        case 'rf_two':
          reach = {
            reach: this.convertValIfImperial(this.userSettings, 4000, 'm'),
            min: this.convertValIfImperial(this.userSettings, 500, 'm'),
            max: this.convertValIfImperial(this.userSettings, 8000, 'm'),
            step: this.convertValIfImperial(this.userSettings, 500, 'm'),
            presets: {
              urban: this.convertValIfImperial(this.userSettings, 4000, 'm'),
              rural: this.convertValIfImperial(this.userSettings, 8000, 'm')
            }
          }
          break
        case 'rf_patrol':
          reach = {
            reach: this.convertValIfImperial(this.userSettings, 2500, 'm'),
            min: this.convertValIfImperial(this.userSettings, 500, 'm'),
            max: this.convertValIfImperial(this.userSettings, 5000, 'm'),
            step: this.convertValIfImperial(this.userSettings, 500, 'm'),
            presets: {
              urban: this.convertValIfImperial(this.userSettings, 1000, 'm'),
              rural: this.convertValIfImperial(this.userSettings, 4000, 'm')
            }
          }
          break
        default:
          reach = {
            reach: this.convertValIfImperial(this.userSettings, 8000, 'm'),
            min: this.convertValIfImperial(this.userSettings, 5000, 'm'),
            max: this.convertValIfImperial(this.userSettings, 8000, 'm'),
            step: this.convertValIfImperial(this.userSettings, 500, 'm'),
            presets: {
              urban: this.convertValIfImperial(this.userSettings, 1000, 'm'),
              rural: this.convertValIfImperial(this.userSettings, 4000, 'm')
            }
          }
      }
      return reach
    }
  },
  methods: {
    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial,

    RFTypeChanged(model) {
      this.sensor.reach =
        model === 'rf_zero'
          ? this.convertValIfImperial(this.userSettings, 4000, 'm')
          : model === 'rf_two'
          ? this.convertValIfImperial(this.userSettings, 8000, 'm')
          : model === 'rf_patrol'
          ? this.convertValIfImperial(this.userSettings, 5000, 'm')
          : this.convertValIfImperial(this.userSettings, 2500, 'm')
      this.$_autoName(model)
    },
    detectionVisualChanged(payload) {
      this.sensor.show_sectors = payload.show_sectors
      this.sensor.show_sector_as_line = payload.show_sector_as_line
    },
    previewOutsideDetection() {
      if (this.previewingDetection === true) return
      this.previewingDetection = true
      setTimeout(() => {
        this.previewingDetection = false
      }, 5000)
    }
  },
  mounted() {
    this.$_updateOffset()
    this.$_initName(2)

    // If creating an unregistered sensor for the first time
    if (this.sensor && this.sensor.id === undefined)
      this.RFTypeChanged(this.sensor.model)

    let selectedVal = this.$refs.installationSelect?.$props?.value
    if (selectedVal !== null) {
      this.selectInstallationError = false
      this.selectInstallationSuffix = ''
    }
    this.sensor.reach = this.convertValIfImperial(
      this.userSettings,
      this.sensor.reach,
      'm',
      1
    )
  }
}
</script>

<style>
.reachPreset {
  position: absolute;
  right: 1rem;
  transform: translateY(-0.75rem);
  z-index: 0;
}

.reachPreset span span {
  height: 28px;
  padding: 0 10px;
}

.v-slider__thumb-label.primary {
  z-index: 1;
}

.reachPreset .v-chip .v-chip__content {
  cursor: pointer !important;
}

.outside-detections-switch {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
}
.preview-wrapper {
  display: flex;
  flex-direction: column;
  line-height: 18px;
}
.preview-button-text {
  text-align: start;
  font-size: 100%;
  text-decoration: underline;
  cursor: pointer;
}

.disabled-preview {
  text-decoration: none;
  cursor: inherit;
}
</style>
