





























































































































































































































































































































































































































































































import { FormMixin, SensorMixin } from '@/components/Mixins'
import { mapGetters } from 'vuex'
import utils from '@/utils/utils'
import {
  convertValIfImperial,
  convertUnitIfImperial
} from '@/store/utils/index'
import DRangeSlider from '@/components/Base/DRangeSlider.vue'
import { MAX_RANGE, MIN_RANGE } from '@/store/modules/radar_mask_zones/types'
import Vue from 'vue'
import SubHeader from '@/components/Forms/SubHeader.vue'
import { IRadar } from '@/store/modules/radars/types'

const props = {
  radarClassFilter: {
    type: Array,
    default: () => ['Aircraft', 'Vehicle', 'Walker', 'Unknown', 'Other']
  },
  sensorTypes: {
    type: Array,
    default: () => [
      { value: 'radar_zero', text: 'RadarZero' },
      { value: 'rada', text: 'Rada' }
    ]
  },

  radarChannelItems: {
    type: Array,
    default: () => [
      {
        value: 0,
        text: 'Channel 1 - 24.465GHz to 24.510GHz'
      },
      {
        value: 1,
        text: 'Channel 2 - 24.525GHz to 24.570GHz'
      },
      {
        value: 2,
        text: 'Channel 3 - 24.585GHz to 24.630GHz'
      }
    ]
  },
  radarTCMItems: {
    type: Array,
    default: () => [
      {
        value: -1,
        text: 'TCM Disabled'
      },
      {
        value: 0,
        text: 'Time Channel 1'
      },
      {
        value: 1,
        text: 'Time Channel 2'
      },
      {
        value: 2,
        text: 'Time Channel 3'
      },
      {
        value: 3,
        text: 'Time Channel 4'
      }
    ]
  },
  radarClutterWidthMask: {
    type: Array,
    default: () => [
      {
        value: 7,
        text: 'Maximum Speed'
      },
      {
        value: 5,
        text: 'High Speed'
      },
      {
        value: 3,
        text: 'Medium Speed'
      },
      {
        value: 1,
        text: 'Lower Speed'
      }
    ]
  },
  sensor: {
    type: Object as () => IRadar
  },
  compasses: {
    type: Array,
    default: () => []
  },
  sentries: {
    type: Array,
    default: () => []
  },
  authorizeEdit: {
    type: Boolean,
    default: true
  },
  showInstallationSelector: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'radar-form',
  data: () => ({
    valid: false,
    selectInstallationError: true,
    selectInstallationSuffix: 'Please select',
    maxRange: MAX_RANGE,
    minRange: MIN_RANGE
  }),
  components: {
    DRangeSlider,
    SubHeader
  },
  mixins: [FormMixin, SensorMixin],
  props,
  computed: {
    ...mapGetters('users', ['isAdmin']),
    ...mapGetters('plans', ['activePlan']),
    ...mapGetters('sites', ['activeSite']),
    ...mapGetters('radars', ['radarsSet']),
    ...mapGetters('users', ['getDegreeUnit', 'user']),
    userSettings() {
      return this.user.settings
    },
    classificationsFilterOut: {
      get: function(): string[] {
        return (
          this.sensor['classifications_filter_out_list'] &&
          this.sensor['classifications_filter_out_list'].split(',')
        )
      },
      set: function(newValue: string[]) {
        this.sensor['classifications_filter_out_list'] = newValue.join(',')
      }
    },
    realTimeIMUTilt(): number {
      return (this.sensor.id && this.radarsSet[this.sensor.id].imu_tilt) || 0
    },
    realTimeIMURoll(): number {
      return (this.sensor.id && this.radarsSet[this.sensor.id].imu_roll) || 0
    },
    showRadarZeroConfig(): boolean {
      return this.sensor?.radar_type === 'radar_zero'
    }
  },
  methods: {
    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial,
    checkConstraints(aType, aBnd): void {
      const pBnd = aBnd === 'min' ? 'max' : 'min'
      const s = this.sensor
      const pType = aType === 'track' ? 'search' : 'track'
      const minRange = aBnd === 'min' ? s['radar_az_step'] : -s['radar_az_step']

      // Check azimuth track constraints
      if (s[`radar_az_min_track`] >= s[`radar_az_max_track`]) {
        s[`radar_az_${pBnd}_track`] = s[`radar_az_${aBnd}_track`] + minRange
      }
      // Check azimuth search vs track constraints
      if (s[`radar_az_min_search`] < s[`radar_az_min_track`]) {
        s[`radar_az_min_${pType}`] = s[`radar_az_min_${aType}`]
      }
      if (s[`radar_az_max_search`] > s[`radar_az_max_track`]) {
        s[`radar_az_max_${pType}`] = s[`radar_az_max_${aType}`]
      }
      // Check azimuth search constraints
      if (s[`radar_az_min_search`] >= s[`radar_az_max_search`]) {
        s[`radar_az_${pBnd}_search`] = s[`radar_az_${aBnd}_search`] + minRange
      }
      // Check elevation constraints
      if (s[`radar_el_min_${aType}`] > s[`radar_el_max_${aType}`]) {
        s[`radar_el_${pBnd}_${aType}`] = s[`radar_el_${aBnd}_${aType}`]
      }
    }
  },
  mounted(): void {
    if (this.activePlan) {
      this.sensorTypes.push({ value: 'radar_two', text: 'RadarTwo' })
    }
    this.$_updateOffset()
    this.$_initName()

    let selectedVal = this.$refs.installationSelect?.$props.value
    if (selectedVal !== null) {
      this.selectInstallationError = false
      this.selectInstallationSuffix = ''
    }

    this.sensor.altitude &&= this.convertValIfImperial(
      this.userSettings,
      this.sensor.altitude,
      'm',
      1
    )
    this.sensor.height = this.convertValIfImperial(
      this.userSettings,
      this.sensor.height,
      'm',
      1
    )
    this.sensor.reach = this.convertValIfImperial(
      this.userSettings,
      this.sensor.reach,
      'm',
      1
    )
  }
})
