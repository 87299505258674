









































































































































































































































































































































































































































































































































































































import SubHeader from '@/components/Forms/SubHeader.vue'
import CameraCalibrationForm from '@/components/Forms/Sensors/CameraCalibrationForm.vue'
import { SensorMixin, FormMixin } from '@/components/Mixins'
import arcPath from '@/components/Map/utils/arcPath'
import mapUtils from '@/components/Map/utils'
import { mapState, mapGetters, mapActions } from 'vuex'
import { convertValIfImperial, convertUnitIfImperial } from '@/store/utils'
import {IUserSettings} from "@/store/modules/users/types";

const props = {
  sensor: {
    type: Object
  },
  compasses: {
    type: Array,
    default: () => []
  },
  hasCompass: {
    type: Boolean,
    default: false
  },
  sentries: {
    type: Array,
    default: () => []
  },
  sensorTypes: {
    type: Array,
    default: () => [{ value: 'droneopt', text: 'DroneOpt' }]
  },
  parameters: {
    type: Object,
    default: () => ({
      calibrate: false
    })
  },
  showInstallationSelector: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'camera-form',
  components: {
    SubHeader,
    CameraCalibrationForm
  },
  mixins: [FormMixin, SensorMixin],
  props,
  data: () => ({
    valid: false,
    selectInstallationError: true,
    selecInstallationSuffix: 'Please select',
    lock: {
      tilt_angle: true
    },
    tilt_threshold: 130,
    controlLoopSpeeds: [
      { value: -1, text: 'Slower' },
      { value: 0, text: 'Normal' },
      { value: 1, text: 'Faster' }
    ],
    autoTrackItems: [
      { value: 0, text: 'Disabled' },
      { value: 1, text: 'Radar Confirmed' },
      { value: 2, text: 'RF Confirmed' },
      { value: 3, text: 'Radar and RF Confirmed' }
    ]
  }),
  computed: {
    ...mapState('cameras', ['calibratingCamera']),
    ...mapGetters('plans', ['activePlan']),
    ...mapGetters('users', ['getDegreeUnit', 'user']),
    userSettings(): IUserSettings {
      return this.user.settings
    },
    reach(): number {
      return arcPath.calculateWD(this.sensor[`min_fov_angle`])
    },
    zoom(): number {
      const s = this.sensor
      return arcPath.calculateZoom(s[`min_fov_angle`], s[`max_fov_angle`])
    },
    cameraType(): string {
      return this.sensor['camera_type'] || 'droneopt1'
    },
    formattedProbThreshold: {
      get(): number {
        return this.sensor.droneoptid_prob_threshold
      },
      set(val: number) {
        this.sensor.droneoptid_prob_threshold = val
      }
    },
    formattedSlewHFOV: {
      get(): number {
        return this.sensor.tracking_slew_hfov_percent
      },
      set(val: number) {
        this.sensor.tracking_slew_hfov_percent = val
      }
    },
    formattedStreamingBitrate: {
      get(): number {
        return this.sensor.streaming_bitrate
      },
      set(val: number) {
        this.sensor.streaming_bitrate = val
      }
    }
  },
  methods: {
    ...mapActions('cameras', ['CAMERA_CONTROL']),
    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial,
    goToHomePosition(): void {
      this.CAMERA_CONTROL({
        camera_id: this.sensor.id,
        command: 'absoluteMove',
        position: { x: 0, y: 0, z: 0 }
      })
    },
    checkConstraints(attr, aBnd): void {
      const pBnd = aBnd === 'min' ? 'max' : 'min'
      // Check AFOV constraints
      if (this.sensor[`min_${attr}`] > this.sensor[`max_${attr}`]) {
        this.sensor[`${pBnd}_${attr}`] = this.sensor[`${aBnd}_${attr}`]
      }
      if (this.lock[attr]) {
        this.sensor[`${pBnd}_${attr}`] = -this.sensor[`${aBnd}_${attr}`]
      }
    },
    passwordUpdate(v): void {
      // Ensure password is not sent to API when unset by user
      if (v === '') this.sensor.password = null
    },
    handleCameraCalibrationUpdate({ key, value }): void {
      if (key === 'result') {
        const { azimuth, tilt } = value || {}
        const { min, max } = tilt || {}
        if (
          ![min, max].every(t => Math.abs(t) <= this.tilt_threshold) ||
          !(azimuth <= 360 || azimuth >= 0)
        ) {
          return this.$bus.$emit('APP_MESSAGE', {
            color: 'error',
            title: 'Erroneous Calibration',
            message:
              'Calibration values were not applied, please check the calibration helper and remove incorrect measurements.'
          })
        }
        this.sensor.direction_offset = mapUtils.circularBounds(
          azimuth - this.sentryDirection,
          [0, 360]
        )
        this.sensor.direction =
          this.sensor.direction_offset + this.sentryDirection
        this.sensor.min_tilt_angle = tilt.min
        this.sensor.max_tilt_angle = tilt.max
      }
    }
  },
  beforeDestroy(): void {
    this.$emitter.off(
      'cameraCalibrationUpdate',
      this.handleCameraCalibrationUpdate
    )
  },
  mounted(): void {
    // Stored password is encrypted
    // Do not override password unless explicitly set by user
    this.sensor.password = null

    if (this.sensor.min_tilt_angle != -this.sensor.max_tilt_angle) {
      this.lock.tilt_angle = false
    }
    this.$emitter.on(
      'cameraCalibrationUpdate',
      this.handleCameraCalibrationUpdate
    )
    if (this.activePlan) {
      this.sensorTypes = [
        { value: 'droneopt1', text: 'DroneOpt1' },
        { value: 'droneopt2', text: 'DroneOpt2' }
      ]
    }
    this.$_updateOffset()
    this.$_initName()
    if (this.parameters.calibrate) {
      this.$store.dispatch('cameras/CALIBRATE_CAMERA', true)
    }

    let selectedVal = this.$refs.installationSelect.$props.value
    if (selectedVal !== null) {
      this.selectInstallationError = false
      this.selecInstallationSuffix = ''
    }

    this.sensor.altitude = this.convertValIfImperial(
      this.userSettings,
      this.sensor.altitude,
      'm',
      1
    )
  },
  watch: {
    cameraType(type: string) {
      this.$_autoName(type)
    }
  },
  destroyed(): void {
    this.$store.dispatch('cameras/CALIBRATE_CAMERA', false)
  }
}
