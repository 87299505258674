import { IRadar } from '@/store/modules/radars/types'
import { ISentry } from '@/store/modules/sentries/types'

export const ELEVATION_TOLERANCE = 2
export const RCS_TOLERANCE = 5
export const RANGE_TOLERANCE_METRES = 30
export const MIN_RANGE = 20
export const MAX_RANGE = 3000
export const MIN_AZIMUTH = -60
export const MIN_ELEVATION = -40
export const MAX_ELEVATION = 40
export const MIN_RCS = -50
export const MAX_RCS = 100

export interface IRadarZoneMaskState {
  editing: boolean
  radarMaskZonesSet: IRadarZoneMasksSet
  status: string
  error: string
}

export interface IRadarZoneMasksSet {
  [key: number]: IRadarZoneMaskModel[]
}

export interface IRadarZoneMaskModel {
  id: number
  name: string
  radar_id: number
  azimuth_min: number
  azimuth_max: number
  elevation_min: number
  elevation_max: number
  range_min: number
  range_max: number
  velocity_min: number
  velocity_max: number
  rcs_min: number
  rcs_max: number
  hard_mask?: boolean
}

export interface IRadarZoneMask {
  id: number
  azimuth: number[]
  elevation: number[]
  range: number[]
  rcs: number[]
  hard_mask: boolean
  name: string
  radar_id: number
}

export interface IRadarZoneMaskRawData {
  altitude: number
  elevation: number
  latitude: number
  longitude: number
  rcs: number
  azimuth: number
  speed: number
}

export interface IRadarZoneMaskRequest {
  isFusion: boolean
  sensor: IRadar
  sentry: ISentry
  data: IRadarZoneMaskRawData
}

export interface IRadarMaskErrorResponse {
  error: string
}

export interface IRadarMaskListResponse {
  radar_mask_zones: IRadarZoneMaskModel[]
}

export interface IRadarMaskZoneResponse {
  radar_mask_zone: IRadarZoneMaskModel
}
