<template>
  <div class="mb-5 px-4" style="position: relative">
    <v-layout row wrap>
      <v-tooltip
        v-if="!activePlan && !calibratingCamera"
        left
        max-width="90"
        color="sdark"
      >
        <v-btn
          icon
          outline
          absolute
          top
          right
          class="ma-2"
          slot="activator"
          @click="resetDialog = true"
        >
          <v-icon>settings_backup_restore</v-icon>
        </v-btn>
        <span>Reset default parameters</span>
      </v-tooltip>
      <v-flex xs12 class="pt-5">
        <v-alert
          dismissible
          :value="
            error || cameraError || rfSensorError || cannonsError || radarsError
          "
          outline
          color="error"
          @input="dismiss"
          >{{
            error || cameraError || rfSensorError || cannonsError || radarsError
          }}</v-alert
        >
      </v-flex>
      <v-flex xs12>
        <!-- is= radar-form, rf-form, cannon-form... -->
        <component
          @onSubmit="submit"
          @onCancel="cancel"
          :sentries="sentries"
          :compasses="compasses"
          :sensor="sensor"
          :is="`${type}-form`"
          :parameters="parameters"
          :showInstallationSelector="showInstallationSelector"
        ></component>
      </v-flex>
    </v-layout>
    <v-dialog :value="resetDialog" persistent max-width="325">
      <v-card ma-5>
        <v-card-title class="headline">Reset default parameters?</v-card-title>
        <v-card-text>
          Do you really want to reset
          <b>{{ sensor.name }}</b> default parameters? The active parameters
          will be overwritten and are not recoverable.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="p0"
            small
            flat
            @click.native="resetDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="error" small flat @click.native="onReset">Reset</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import RadarForm from './RadarForm'
import RfForm from './RfForm'
import CannonForm from './CannonForm'
import CameraForm from './CameraForm'
import CompassForm from './CompassForm'
import DiscovairForm from './DiscovairForm'
import DronesentryxForm from './DronesentryxForm'

import schema from './schema.js'
import { isDsxModelJamming } from '@/store/modules/rf_sensors/types'
import { convertValIfImperial } from '@/store/utils/index'

const props = {
  type: String,
  sensor: Object,
  sentry: Object,
  parameters: {
    type: Object,
    default: () => ({})
  }
}

export default {
  name: 'sensor-form',
  components: {
    RadarForm,
    RfForm,
    CannonForm,
    CameraForm,
    CompassForm,
    DiscovairForm,
    DronesentryxForm
  },
  data: () => ({
    resetDialog: false,
    sentry_id: 0,
    formMapping: {}
  }),
  props,
  computed: {
    ...mapGetters('system', ['systemSetting']),
    ...mapGetters('sensors', {
      status: 'status',
      error: 'error',
      gps_compassesInSentry: 'gps_compassesInSentry'
    }),
    ...mapState('sites', ['activeSiteId']),
    ...mapGetters('sentries', ['sentriesInSite', 'activeSentryId']),
    ...mapGetters('plans', ['activePlan', 'sentryCoords']),
    ...mapGetters('users', ['isAuthorized']),
    ...mapState('cameras', {
      cameraStatus: 'status',
      calibratingCamera: 'calibratingCamera'
    }),
    ...mapState('rf_sensors', { rfSensorStatus: 'status' }),
    ...mapState('cannons', { cannonStatus: 'status' }),
    ...mapState('radars', { radarStatus: 'status' }),
    ...mapGetters('cameras', { cameraError: 'error' }),
    ...mapGetters('rf_sensors', { rfSensorError: 'error' }),
    ...mapGetters('cannons', { cannonsError: 'error' }),
    ...mapGetters('radars', { radarsError: 'error' }),
    sentries() {
      return this.sentriesInSite(this.activeSiteId)
    },
    authoriseEdit() {
      return this.isAuthorized('site_manager')
    },
    compasses() {
      return [{ name: 'None', id: null }].concat(
        this.gps_compassesInSentry({ id: this.sensor['sentry_id'] })
      )
    },
    // Filter the fields on the type of sensors
    defaultSensorFields() {
      return schema().filter(
        ({ sensors, editOnly }) =>
          (sensors === 'all' ||
            (Array.isArray(sensors) && sensors.includes(this.type))) &&
          !(Array.isArray(editOnly) && editOnly.includes(this.type))
      )
    },
    // inject values if sensor exist
    sensorFields() {
      return this.defaultSensorFields.map(f => ({
        ...f,
        value: (this.sensor && this.sensor[f.key] != null) || f.value || null
      }))
    },
    showInstallationSelector() {
      return (
        (!this.activePlan && !this.sentry && !this.sentry_id) ||
        this.sensor.id > 0
      )
    }
  },
  methods: {
    ...mapActions('sensors', [
      'CREATE_RADAR',
      'UPDATE_RADAR',
      'CREATE_GPS_COMPASS',
      'UPDATE_GPS_COMPASS',
      'CREATE_DISCOVAIR_SENSOR',
      'UPDATE_DISCOVAIR_SENSOR'
    ]),
    ...mapActions('cameras', ['CREATE_CAMERA', 'UPDATE_CAMERA']),
    ...mapActions('rf_sensors', ['CREATE_RF_SENSOR', 'UPDATE_RF_SENSOR']),
    ...mapActions('radars', ['CREATE_RADAR', 'UPDATE_RADAR']),
    ...mapActions('cannons', ['CREATE_CANNON', 'UPDATE_CANNON']),
    ...mapActions('plans', {
      createPlanSensor: 'CREATE_SENSOR',
      updatePlanSensor: 'UPDATE_SENSOR'
    }),
    ...mapActions('sentries', { selectSentry: 'SELECT_SENTRY' }),
    onUpdateValue(value) {
      this.sensor = Object.assign(this.sensor, value)
    },

    convertValIfImperial: convertValIfImperial,
    checkRequestStatus(type) {
      switch (type) {
        case 'camera':
          return this.cameraStatus
          break
        case 'rf':
        case 'dronesentryx':
          return this.rfSensorStatus
          break
        case 'cannon':
          return this.cannonStatus
          break
        case 'radar':
          return this.radarStatus
          break
        default:
          return this.status
      }
    },
    mapMethod(action, sensor) {
      const isDsx = this.type.includes('dronesentryx')
      if (this.type.includes('rf') || isDsx) {
        return this[`${action}_RF_SENSOR`]({
          siteId: this.activeSiteId,
          rf_sensor: sensor,
          dsx: isDsx
        })
      } else if (this.type.includes('radar')) {
        return this[`${action}_RADAR`]({
          siteId: this.activeSiteId,
          radar: sensor
        })
      } else if (this.type.includes('camera')) {
        return this[`${action}_CAMERA`]({
          siteId: this.activeSiteId,
          camera: sensor
        })
      } else if (this.type.includes('cannon')) {
        return this[`${action}_CANNON`]({
          siteId: this.activeSiteId,
          cannon: sensor
        })
      } else if (this.type.includes('compass')) {
        return this[`${action}_GPS_COMPASS`](sensor)
      } else if (this.type.includes('discovair')) {
        return this[`${action}_DISCOVAIR_SENSOR`](sensor)
      }
    },
    create(sensor) {
      if (this.activePlan) return this.createPlanSensor(sensor)
      return this.mapMethod('CREATE', sensor)
    },
    update(sensor) {
      if (this.activePlan) return this.updatePlanSensor(sensor)
      return this.mapMethod('UPDATE', sensor)
    },
    cancel() {
      //deactivate sentry once cancelled to stop compass from showing on map
      this.selectSentry(null)
      // this to avoid to add the sensor in the store when clicking cancel
      this.sensor = null
    },
    async submit() {
      const res = {}

      for (let { key, type, siteLinked } of this.sensorFields) {
        if (siteLinked || this.sensor[key] != null) {
          res[key] =
            type === Boolean
              ? !!this.sensor[key]
              : Array.isArray(this.sensor[key])
              ? this.sensor[key].join()
              : this.sensor[key]
        }
      }

      if (this.activePlan) {
        if (this.type === 'rf') res.type = 'rf_sensors'
        else if (this.type === 'discovair') {
          res.type = 'discovair_sensors'
        } else if (this.type === 'dronesentryx') {
          res.type = 'dsx_sensors'
        } else res.type = this.type + 's'

        const sentryCoordinates = this.sentryCoords(this.activeSentryId)
        res.latitude = sentryCoordinates[0]
        res.longitude = sentryCoordinates[1]
      } else {
        delete res['camera_type']
        delete res['field_of_view']
      }

      if (this.type === 'discovair') {
        //delete res['reach'] added back reach @Blue, please review
        delete res['show_sectors']
        delete res['show_sector_as_line']
        delete res['show_outside_detections']
      }

      if (this.type === 'camera' && !this.activePlan) {
        delete res['direction']
      }

      if (this.type === 'dronesentryx') {
        //check if model is not jamming, if not
        if (!isDsxModelJamming(res.model)) {
          delete res['reach_jamming']
        }
      }

      res.sentry_id = this.sensor.sentry_id
      res.override_gps_compass_id =
        this.sensor.override_gps_compass_id !== 'None'
          ? this.sensor.override_gps_compass_id
          : undefined

      // if imperial, save as metric
      if (this.type === 'rf')
        res.reach = this.convertValIfImperial(
          this.systemSetting,
          this.sensor.reach,
          'ft'
        )

      if (this.type === 'camera')
        res.altitude = this.convertValIfImperial(
          this.systemSetting,
          this.sensor.altitude,
          'ft',
          1
        )

      if (this.type === 'radar') {
        if (res.min_max_rcs) {
          let min_max_rcs = res.min_max_rcs.split(',')
          delete res['min_max_rcs']
          res.min_rcs = parseInt(min_max_rcs[0])
          res.max_rcs = parseInt(min_max_rcs[1])
        }

        // force the 90 degrees sector
        if (res.type === 'rada') {
          res.radar_az_max_search = 45
          res.radar_az_min_search = -45
        }
      }

      res.height = this.convertValIfImperial(
        this.systemSetting,
        this.sensor.height,
        'ft',
        1
      )
      res.reach = this.convertValIfImperial(
        this.systemSetting,
        this.sensor.reach,
        'ft',
        1
      )
      if (this.type === 'dronesentryx') {
        res.reach = this.convertValIfImperial(
          this.systemSetting,
          this.sensor.reach,
          'ft'
        )
        res.reach_jamming = this.convertValIfImperial(
          this.systemSetting,
          this.sensor.reach_jamming,
          'ft'
        )
      }

      if (this.type === 'cannon')
        res.reach = this.convertValIfImperial(
          this.systemSetting,
          this.sensor.reach,
          'ft'
        )

      if (this.sensor.id !== undefined) {
        await this.update(
          Object.assign(
            { ...res, id: this.sensor.id },
            this.type === 'radar' ? { manual: true } : {}
          )
        )
      } else {
        await this.create(res)
      }
      if (!this.activeSentryId) this.selectSentry(res.sentry_id)
      if (this.checkRequestStatus(this.type) === 'OK' || this.activePlan) {
        return setTimeout(() => this.$emit('onClickSave'), 500)
      }
    },
    dismiss(v) {
      if (!v) {
        this.$store.commit('sensors/SET_ERROR', null)
        this.$store.commit('sensors/SET_STATUS', null)
      }
    },
    onReset() {
      this.defaultSensorFields.map(({ key, value, doNotReset }) => {
        if (!doNotReset) this.sensor[key] = value
      })
      this.resetDialog = false
    }
  },
  mounted() {
    // this will capture the sentry_id and will keep the installation selector
    // showing even if the sentry is selected
    this.sentry_id = this.sensor.sentry_id
  }
}
</script>

<style>
.sensorForm {
  background-color: #424242;
}
</style>
