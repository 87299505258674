<template>
  <div>
    <template v-if="calibratingCamera">
      <FusionDetection
        v-for="detection in fusionDetectionItems"
        :detection="detection"
        class="fusion-detection"
        :key="`fusion-detection_${detection.target_id}`"
        :hide-location-variance="true"
      >
        <template slot="popup">
          <v-layout row>
            <v-flex>
              <b>Altitude:</b>
              {{ detection.altitude }}
            </v-flex>
          </v-layout>
        </template>
      </FusionDetection>

      <!-- TODO: display radars too if green/in sentry? -->
      <l-circle
        color="green"
        fill-color="transparent"
        :opacity="0.3"
        :weight="40"
        :radius="150"
        :lat-lng="[sensor.latitude, sensor.longitude]"
      />
    </template>

    <sentry-marker
      v-if="!!calibratingCamera"
      icon-color="green"
      :sentry="sentry"
      :active-sentry-id="sensor.sentry_id"
      :sensors="cameras"
    />
  </div>
</template>

<script>
import { SentryMarker } from '../Marker'
import { mapGetters, mapState, mapActions } from 'vuex'
import FusionDetection from '@/components/Map/Detection/SensorFusion/FusionDetection'

import bearing from '@turf/bearing'
import distance from '@turf/distance'
import { point } from '@turf/helpers'
import { LCircle } from 'vue2-leaflet'
import utils from '@/components/Map/utils'
import { ESiteMode } from '@/store/modules/sites/types'

// TODO use https://www.movable-type.co.uk/scripts/geodesy/docs/module-latlon-ellipsoidal-referenceframe-LatLonEllipsoidal_ReferenceFrame.html#LatLonEllipsoidal_ReferenceFrame
// to get height difference
// import { LatLonEllipsoidal } from 'geodesy'

const props = {
  sensor: {
    type: Object,
    default: () => ({
      latitude: 0,
      longitude: 0
    })
  },
  sentry: {
    type: Object,
    default: () => ({})
  }
}

export default {
  name: 'CameraCalibrationLayer',
  components: {
    FusionDetection,
    SentryMarker,
    LCircle
  },
  props,
  data: () => ({
    intervals: {},
    radarDetections: {},
    cameraDetections: {},
    detectionsCalibration: {
      tilt: { x: [], y: [] },
      pan: []
    },
    lostCameraDetectionCount: 0,
    calibrationTargetAltitude: null,
    calibrationInitialBearing: null,
    tiltCalibrationDone: false
  }),
  computed: {
    ...mapGetters('sites', ['activeSite']),
    ...mapState('selection', ['activeCamera']),
    ...mapState('detection', ['fusionDetections', 'selectedDetections']),
    ...mapState('cameras', ['calibratingCamera', 'camerasSet']),
    fusionDetectionItems() {
      return Object.values(this.fusionDetections)
    },
    pointOrigin() {
      return point([this.sensor.longitude, this.sensor.latitude])
    },
    cameras() {
      return { cameras: Object.values(this.camerasSet) }
    }
  },
  methods: {
    ...mapActions('detection', {
      addDetection: 'addDetection',
      selectFusionDetection: 'selectDetection',
      clearSelectedDetections: 'clearSelectedDetections'
    }),
    calculateHomePosition(targetCoords, value = null) {
      const angle = bearing(
        this.pointOrigin,
        point([targetCoords.longitude, targetCoords.latitude])
      )
      const homeAngle = utils.circularBounds(
        angle - (value || this.activeCamera.pan) * 180,
        [0, 360]
      )
      return homeAngle
    },
    addCalibrationDetection(detection, toProcess = 'all', value = null) {
      if (['all', 'tilt'].includes(toProcess)) {
        const dist =
          1000 *
          distance(this.pointOrigin, [detection.longitude, detection.latitude])
        const camera_altitude =
          this.activeCamera.altitude + this.activeCamera.sentry_altitude
        // TODO get adjusted altitude using geodesy, same on smarthub
        // check if that really makes a difference 150m away
        let vertAngle =
          Math.atan((detection.altitude - camera_altitude) / dist) *
          (180 / Math.PI)
        this.detectionsCalibration.tilt.x.push(vertAngle)
        this.detectionsCalibration.tilt.y.push(value || this.activeCamera.tilt)
      }
      if (['all', 'pan'].includes(toProcess)) {
        this.detectionsCalibration.pan.push(
          this.calculateHomePosition(detection, value)
        )
      }
    },
    sum(array) {
      return array.reduce((a, b) => a + b, 0)
    },
    degToRad(alpha) {
      return (Math.PI / 180) * alpha
    },
    mean(array) {
      const meanAngle =
        (180 / Math.PI) *
        Math.atan2(
          this.sum(array.map(this.degToRad).map(Math.sin)) / array.length,
          this.sum(array.map(this.degToRad).map(Math.cos)) / array.length
        )
      return this.roundNDigits(meanAngle < 0 ? meanAngle + 360 : meanAngle)
    },
    roundNDigits(x, n = 2) {
      let factor = Math.pow(10, n)
      return Math.round(x * factor) / factor
    },
    linearRegression(data) {
      let n = data.y.length
      let [x, y] = [[...data.x], [...data.y]]
      let slope, intercept, r2

      if (n == 1) {
        slope = this.sensor.active_stream ? 0.01456664239 : 0.013422 // Trakka or Bosch
        intercept = y[0] - slope * x[0]
        r2 = 1
      } else {
        let sum_x = 0,
          sum_y = 0,
          sum_xy = 0,
          sum_xx = 0,
          sum_yy = 0

        for (var i = 0; i < y.length; i++) {
          sum_x += x[i]
          sum_y += y[i]
          sum_xy += x[i] * y[i]
          sum_xx += x[i] * x[i]
          sum_yy += y[i] * y[i]
        }

        slope = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x)
        intercept = (sum_y - slope * sum_x) / n
        r2 = Math.pow(
          (n * sum_xy - sum_x * sum_y) /
            Math.sqrt(
              (n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)
            ),
          2
        )
      }
      return {
        slope,
        intercept,
        r2: this.roundNDigits(r2),
        min: this.roundNDigits((-1 - intercept) / slope),
        max: this.roundNDigits((1 - intercept) / slope)
      }
    },
    handleRadarDetectionsCreate(data) {
      const { target_id, altitude } = data
      if (this.selectedDetections.includes(target_id) && altitude) {
        this.$emitter.emit('cameraCalibrationUpdate', {
          key: 'radar_detection',
          value: data
        })
      }
      const oldDetection = this.radarDetections[target_id]
      if (oldDetection) {
        clearTimeout(oldDetection.timeout)
      }
      this.radarDetections = Object.assign({}, this.radarDetections, {
        ...this.radarDetections,
        [target_id]: {
          ...data,
          count: (oldDetection?.count || 0) + 1,
          historyCoords: [
            [data.longitude, data.latitude],
            ...(oldDetection?.historyCoords || [])
          ],
          historyAlt: [data.altitude, ...(oldDetection?.historyAlt || [])],
          timeout: setTimeout(
            () => delete this.radarDetections[target_id],
            2000
          )
        }
      })
    },
    handleCameraDetectionCreate(data) {
      // if (!d.active_track) return;
      const { x, y } = data.detection_contributions?.[0] || {}

      if (!x || !y) return

      this.$emitter.emit('cameraCalibrationUpdate', {
        key: 'camera_detection',
        value: data
      })

      const { target_id } = data
      const oldDetection = this.cameraDetections[target_id]
      if (oldDetection) {
        clearTimeout(oldDetection.timeout)
      }

      this.cameraDetections = Object.assign({}, this.cameraDetections, {
        ...this.cameraDetections,
        [target_id]: {
          ...data,
          count: (oldDetection?.count || 0) + 1,
          historyPos: [[x, y], ...(oldDetection?.historyPos || [])],
          timeout: setTimeout(
            () => delete this.cameraDetections[target_id],
            2000
          )
        }
      })
    },
    clearIntervals(select = 'all') {
      Object.keys(this.intervals).forEach(i => {
        if (select !== 'all' && i !== select) return
        clearInterval(this.intervals[i])
        delete this.intervals[i]
      })
    },
    applyCalibrationResults() {
      this.$emitter.emit('cameraCalibrationUpdate', {
        key: 'result',
        value: {
          azimuth: this.mean(this.detectionsCalibration.pan),
          tilt: this.linearRegression(this.detectionsCalibration.tilt)
        }
      })
    },
    handleCameraCalibrationUpdate({ key, value }) {
      switch (key) {
        case 'tilt_update':
          const { detection: tiltDetection, tilt } = value
          if (tilt === 1) return
          this.addCalibrationDetection(tiltDetection, 'tilt', tilt)
          this.$emitter.emit('cameraCalibrationUpdate', {
            key: 'tilt_calibration',
            value: {
              data: this.detectionsCalibration.tilt,
              result: this.linearRegression(this.detectionsCalibration.tilt)
            }
          })
          break
        case 'pan_update':
          const { detection: panDetection, pan } = value
          this.addCalibrationDetection(panDetection, 'pan', pan)
          this.$emitter.emit('cameraCalibrationUpdate', {
            key: 'pan_calibration',
            value: {
              data: this.detectionsCalibration.pan,
              result: this.mean(this.detectionsCalibration.pan)
            }
          })
      }
    },
    nextStep() {
      clearInterval(this.intervals.main)
      this.$emitter.emit('cameraCalibrationUpdate', {
        key: 'next_step'
      })
    },
    startRadarLostCheck() {
      // if radar lost check isn't running yet
      if (!this.intervals.radarDetection) {
        // every 1s, check whether radar detection was lost
        this.intervals.radarDetection = setInterval(() => {
          if (!this.radarDetections[this.selectedDetections?.[0]]) {
            // unselect detection
            this.clearSelectedDetections()
            console.log('lost radar')
            // notify camera calibration form
            this.$emitter.emit('cameraCalibrationUpdate', {
              key: 'radar_lost'
            })
            // stop checking whether radar detection was lost
            this.clearIntervals('radarDetection')
            // interrupt current main task
            this.clearIntervals('main')
          }
        }, 1000)
      }
    },
    startCameraLostCheck() {
      // if camera lost check isn't running yet
      if (!this.intervals.cameraDetection) {
        this.lostCameraDetectionCount = 0
        // every 1s, check whether camera detection was lost
        this.intervals.cameraDetection = setInterval(() => {
          if (Object.values(this.cameraDetections).length) {
            this.lostCameraDetectionCount = 0
          } else {
            this.lostCameraDetectionCount += 1
            // if the camera detection was lost 3s in a row
            if (this.lostCameraDetectionCount >= 3) {
              // notify camera calibration form
              this.$emitter.emit('cameraCalibrationUpdate', {
                key: 'camera_lost'
              })
              // stop checking whether camera detection was lost
              this.clearIntervals('cameraDetection')
              // stop current main interval
              this.clearIntervals('main')
            }
          }
        }, 1000)
      }
    }
  },
  beforeDestroy() {
    this.clearIntervals()
    this.$bus.$off(`SOCKET/SENSOR_FUSION_DETECTION_CREATE`)
    this.$bus.$on(`SOCKET/SENSOR_FUSION_DETECTION_CREATE`, payload => {
      const { data } = payload.message
      this.addDetection(data)
    })
    this.$emitter.off(
      'cameraCalibrationUpdate',
      this.handleCameraCalibrationUpdate
    )
  },
  mounted() {
    this.clearSelectedDetections()
    this.$bus.$off(`SOCKET/SENSOR_FUSION_DETECTION_CREATE`)
    this.$bus.$on(`SOCKET/SENSOR_FUSION_DETECTION_CREATE`, payload => {
      // Ignore detections if not in calibration mode
      if (this.activeSite.mode !== ESiteMode.Calibration) return
      const { data } = payload.message
      if (data.radar_confirmed) {
        this.addDetection(data)
        this.handleRadarDetectionsCreate(data)
      } else if (data.camera_confirmed) {
        this.handleCameraDetectionCreate(data)
      }
    })
    this.$emitter.on(
      'cameraCalibrationUpdate',
      this.handleCameraCalibrationUpdate
    )
  },
  watch: {
    calibratingCamera(v) {
      switch (v) {
        // Camera calibration step 1 - select radar detection
        case 'cal-1-0':
          const RADAR_MIN_DETECTION_COUNT = 30
          const AVG_COUNT = 30
          const TARGET_ALTITUDE = 25
          const TARGET_DISTANCE = 150
          const DISTANCE_TOLERANCE = 12
          // every 2s, find radar target_id matching criteria
          this.intervals.main = setInterval(() => {
            const { target_id } =
              Object.values(this.radarDetections).find(d => {
                // minimum count (not a short-lived detection)
                if (d.count < RADAR_MIN_DETECTION_COUNT) return false
                // maximum location variance (detection does not move)
                if (d.historyCoords[AVG_COUNT] === undefined) return false
                const hasNotMoved =
                  distance(d.historyCoords[0], d.historyCoords[AVG_COUNT]) <
                    0.005 &&
                  Math.abs(d.historyAlt[0] - d.historyAlt[AVG_COUNT]) < 5
                // bounded location (within green band)
                const rightSpot =
                  Math.abs(
                    distance(this.pointOrigin, d.historyCoords[0]) * 1000 -
                      TARGET_DISTANCE
                  ) < DISTANCE_TOLERANCE &&
                  Math.abs(d.historyAlt[0] - TARGET_ALTITUDE) <
                    DISTANCE_TOLERANCE
                return hasNotMoved && rightSpot
              }) || {}

            // select target_id matching criteria to trigger next calibration step
            if (target_id) {
              this.selectFusionDetection(target_id)
              clearInterval(this.intervals.main)
            }
          }, 2000)
          break

        case 'cal-1-1':
          // Camera calibration step 1.1 - initialise camera tracking

          // after radar detection is selected, make sure it's never lost
          this.startRadarLostCheck()

          // if camera tracking is already initialised, skip to next step
          if (this.intervals.cameraDetection) {
            return this.nextStep()
          }

          // camera detection selection criteria
          const CAMERA_MIN_DETECTION_COUNT = 15
          const CAMERA_INDEX_FOR_AVG = CAMERA_MIN_DETECTION_COUNT - 5
          const CAMERA_POSITION_TOLERANCE = 10

          // every 2s, check whether camera detections respect criteria
          this.intervals.main = setInterval(() => {
            const centred = Object.values(this.cameraDetections).some(d => {
              // minimum count (not a short-lived detection)
              if (d.count < CAMERA_MIN_DETECTION_COUNT) return false
              // detection centred
              return (
                Math.abs(d.historyPos[0][0] - 50) < CAMERA_POSITION_TOLERANCE &&
                Math.abs(d.historyPos[CAMERA_INDEX_FOR_AVG][0] - 50) <
                  CAMERA_POSITION_TOLERANCE &&
                Math.abs(d.historyPos[0][1] - 50) < CAMERA_POSITION_TOLERANCE &&
                Math.abs(d.historyPos[CAMERA_INDEX_FOR_AVG][1] - 50) <
                  CAMERA_POSITION_TOLERANCE
              )
            })
            if (centred) {
              this.nextStep()
            }
          }, 2000)
          break

        case 'cal-2-0':
          // Camera tilt calibration step 2 - fly drone 50m higher

          // after camera detection is selected, make sure it's never lost
          this.startCameraLostCheck()

          // if camera tilt calibration was done previously, skip to next step
          if (this.tiltCalibrationDone) {
            return this.nextStep()
          }

          const MIN_ALTITUDE_DIFF = 50
          const MIN_ALTITUDE_TOLERANCE = 2

          // calculate target altitude if not done previously
          if (!this.calibrationTargetAltitude) {
            const { altitude } =
              this.radarDetections?.[this.selectedDetections?.[0]] || {}
            if (!altitude) {
              return console.error("could not get radar detection's altitude")
            }
            // calculate target altitude (50m higher than initial radar detection altitude)
            this.calibrationTargetAltitude = altitude + MIN_ALTITUDE_DIFF
            // notify camera calibration form of target altitude
            this.$emitter.emit('cameraCalibrationUpdate', {
              key: 'target_altitude',
              value: this.calibrationTargetAltitude
            })
          }

          // every 2s, check whether target altitude was reached
          this.intervals.main = setInterval(() => {
            const { altitude: currentAltitude } =
              this.radarDetections?.[this.selectedDetections?.[0]] || {}
            if (!currentAltitude) {
              return console.warn("could not get radar detection's altitude")
            }
            if (
              currentAltitude - this.calibrationTargetAltitude >
              -MIN_ALTITUDE_TOLERANCE
            ) {
              // TODO: check whether calibration is OK or try again
              // this.linearRegression(this.detectionsCalibration.tilt).r2 should be close to 1
              this.tiltCalibrationDone = true
              this.nextStep()
            }
          }, 2000)
          break
        case 'cal-3-0':
          // Camera pan calibration step 3 - fly drone sideways

          // calculate initial bearing if not done previously
          if (!this.calibrationInitialBearing) {
            const { latitude: lat, longitude: lon } =
              this.radarDetections?.[this.selectedDetections?.[0]] || {}
            if (!lat || !lon) {
              return console.error(
                "could not get radar detection's coordinates"
              )
            }
            this.calibrationInitialBearing = bearing(
              this.pointOrigin,
              point([lon, lat])
            )
          }

          const MIN_BEARING_DIFF = 30

          // every 100ms, check whether target bearing was reached
          this.intervals.main = setInterval(() => {
            // calculate current bearing
            const { latitude: lat, longitude: lon } =
              this.radarDetections?.[this.selectedDetections?.[0]] || {}
            if (!lat || !lon) {
              return console.warn("could not get radar detection's coordinates")
            }
            const currentBearing = bearing(this.pointOrigin, point([lon, lat]))

            // calculate bearing difference
            let diffAngle =
              ((currentBearing - this.calibrationInitialBearing + 180) % 360) -
              180
            if (diffAngle > 180) diffAngle += 360

            // stream current bearing difference to camera calibration form
            this.$emitter.emit('cameraCalibrationUpdate', {
              key: 'camera_angle',
              value: diffAngle
            })

            if (Math.abs(diffAngle) >= MIN_BEARING_DIFF) {
              // TODO: check whether calibration is OK or try again
              this.applyCalibrationResults()
              this.nextStep()
            }
          }, 100)
          break
      }
    }
  }
}
</script>

<style>
#calibrationHelper {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0px;
  right: 0px;
  display: block;
  z-index: 9999;
}
</style>
