














































import Vue from 'vue'

import {
  EContributionStateType,
  EDetectionState,
  IDetection,
  IDetectionContribution,
  IDetectionOnly,
  IDetectionPosition,
  IBeaconPosition,
  IContributorHistoryItem
} from '@/store/modules/detection/types'
import { mapActions, mapGetters, mapState } from 'vuex'

const RfSector = () => import('./RfSector.vue')
const DroneSentryXDetectionSector = () =>
  import('./DronesentryxDetectionSector.vue')
const FusionDetectionDot = () => import('./FusionDetectionDot.vue')
import { validBeaconLocation } from '@/utils/utils.js'

import BeaconControllerMarker from '../../Marker/BeaconController.vue'
import BeaconHomeMarker from '../../Marker/BeaconHome.vue'

const props = {
  interactive: {
    type: Boolean,
    default: true
  },
  detection: {
    type: Object as () => IDetection,
    default: () => {}
  },
  hideLocationVariance: {
    type: Boolean,
    default: false
  },
  overrideSentryLocation: {
    type: Object as () => IDetectionPosition,
    default: null
  },
  replaying: {
    type: Boolean,
    default: false
  }
}

interface IFusionDetectionData {
  staleTimer: number
  droneLocatorCorrelationKey: string
}

export default Vue.extend({
  name: 'FusionDetection',
  props,
  provide() {
    return {
      getSentryLocationOverride: () => this.overrideSentryLocation
    }
  },
  components: {
    RfSector,
    FusionDetectionDot,
    DroneSentryXDetectionSector,
    BeaconControllerMarker,
    BeaconHomeMarker
  },
  data(): IFusionDetectionData {
    return {
      staleTimer: null,
      droneLocatorCorrelationKey: null
    }
  },
  mounted() {
    if (this.cameraTracking) {
      this.selectDetection(this.detectionKey)
    }
    if (this.rawDroneLocator) {
      // Fused DroneLocator will cause raw detection to be hidden
      this.$bus.$on('hideRawDroneLocator', this.hideRawDroneLocatorHandler)
    }
  },
  beforeDestroy() {
    if (this.rawDroneLocator) {
      this.$bus.$off('hideRawDroneLocator', this.hideRawDroneLocatorHandler)
    }
    clearTimeout(this.staleTimer)
    if (this.isSelected) {
      this.removeSelectedDetection(this.detectionKey)
    }
  },
  computed: {
    ...mapState('detection', ['selectedDetections', 'activeRfBeam']),
    ...mapGetters('detection', [
      'receivedRadarContribution',
      'receivedRfContribution',
      'receivedCameraContribution',
      'getSeenContributors'
    ]),
    ...mapState('cameras', ['camerasSet']),
    ...mapGetters('sites', ['activeSite']),
    cameras(): any[] {
      return Object.values(this.camerasSet)
    },
    cameraTracking(): boolean {
      return this.cameras.some(
        camera => camera.tracked_target_id === this.detectionKey
      )
    },
    showRfSector(): boolean {
      if (this.activeSite.always_show_rf_beam) return true

      return (
        (this.detection.latitude === 0 && this.detection.longitude === 0) ||
        (this.detection.latitude == null && this.detection.longitude == null)
      )
    },
    isThreat(): boolean {
      return this.detection.state !== EDetectionState.Whitelisted
    },
    position(): IDetectionPosition {
      return {
        lat: this.detection.latitude,
        lng: this.detection.longitude
      }
    },
    beaconGcsPosition(): IBeaconPosition | false {
      const { gcs_latitude, gcs_longitude } = this.detection
      if (
        validBeaconLocation(gcs_latitude) &&
        validBeaconLocation(gcs_longitude)
      )
        return [gcs_latitude, gcs_longitude]
      else return false
    },
    beaconHomePosition(): IBeaconPosition | false {
      const { home_latitude, home_longitude } = this.detection
      if (
        validBeaconLocation(home_latitude) &&
        validBeaconLocation(home_longitude)
      )
        return [home_latitude, home_longitude]
      else return false
    },
    showDot(): boolean {
      return !!this.detection.latitude && !!this.detection.longitude
    },
    isSelected(): boolean {
      return this.selectedDetections.includes(this.detectionKey)
    },
    detectionKey(): string {
      return this.detection.target_id
    },
    rfContributors(): IDetectionContribution[] {
      return this.getRfContributors(this.detection)
    },
    dsxContributors(): IDetectionContribution[] {
      return this.getDsxContributors(this.detection)
    },
    detectionOnly(): IDetectionOnly {
      return this.getDetectionOnly(this.detection)
    },
    seenContributors(): IContributorHistoryItem[] {
      return this.getSeenContributors(this.detection.target_id)
    },
    rawDroneLocator(): boolean {
      // DroneLocator confirmed detection with no location variance is raw detection
      return (
        this.detection.drone_locator_confirmed &&
        this.detection.location_variance === 0
      )
    }
  },
  methods: {
    ...mapActions('detection', [
      'removeDetection',
      'removeSelectedDetection',
      'selectDetection',
      'gotFirstContribution',
      'addSeenContributor',
      'deleteSeenContributors',
      'selectRFBeam'
    ]),
    hideRawDroneLocatorHandler(fusedCorrelationKey: string) {
      if (this.droneLocatorCorrelationKey === fusedCorrelationKey) {
        this.clearDetection()
      }
    },
    getContributors(detection: IDetection): IDetectionContribution[] {
      return detection.detection_contributions || []
    },
    getRfContributors(detection: IDetection): IDetectionContribution[] {
      return (
        this.getContributors(detection)?.filter(contributor => {
          return (
            contributor.sensor_type === 'rfSensor' &&
            !(detection.drone_locator_confirmed && contributor.aoa === 0)
          )
        }) || []
      )
    },
    getDsxContributors(detection: IDetection): IDetectionContribution[] {
      return (
        this.getContributors(detection)?.filter(contributor => {
          return (
            contributor.sensor_type === 'dsx' &&
            !(detection.drone_locator_confirmed && contributor.aoa === 0)
          )
        }) || []
      )
    },
    getDetectionOnly(detection: IDetection): IDetectionOnly {
      const det = Object.assign({}, detection)
      delete det.detection_contributions
      return det
    },
    onSelectRfBeam(targetId: string, key: string) {
      this.selectRFBeam(key)
      this.selectDetection(targetId)
      this.$emit('selectSfRfBeam')
    },
    clearDetection() {
      this.removeDetection(this.detectionKey)
      this.deleteSeenContributors(this.detectionKey)
    }
  },
  watch: {
    detection: {
      handler: function(detection: IDetection) {
        clearTimeout(this.staleTimer)

        // record contributor history
        const contributors = this.getContributors(detection)
        contributors.forEach(item => {
          const contributorItem = {
            id: item.sensor_id,
            type: item.sensor_type
          }

          if (
            this.detection.drone_locator_confirmed &&
            item.correlation_key !== '' &&
            item.aoa === 0 &&
            this.droneLocatorCorrelationKey !== item.correlation_key
          ) {
            this.droneLocatorCorrelationKey = item.correlation_key
            // Newly fused DroneLocator detection causes raw detection to be hidden
            if (!this.rawDroneLocator) {
              this.$bus.$emit('hideRawDroneLocator', item.correlation_key)
            }
          }

          if (
            !this.seenContributors.some((item: IContributorHistoryItem) => {
              return (
                item.id === contributorItem.id &&
                item.type === contributorItem.type
              )
            })
          ) {
            this.addSeenContributor({
              target_id: this.detectionKey,
              contributor: contributorItem
            })
          }
        })

        if (
          !this.receivedRadarContribution ||
          !this.receivedRfContribution ||
          !this.receivedCameraContribution
        ) {
          contributors.forEach(contrib => {
            if (['rfSensor', 'dsx'].includes(contrib.sensor_type)) {
              this.gotFirstContribution(EContributionStateType.RF)
            } else if (contrib.sensor_type === 'radar') {
              this.gotFirstContribution(EContributionStateType.RADAR)
            } else if (contrib.sensor_type === 'camera') {
              this.gotFirstContribution(EContributionStateType.CAMERA)
            }
          })
        }

        let displayTimeout = 5 // default to 5s
        // Drive raw DroneLocator detection timeout in base on Site setting
        if (this.rawDroneLocator) {
          displayTimeout = this.activeSite.drone_locator_detection_timeout
        }

        this.staleTimer = setTimeout(this.clearDetection, displayTimeout * 1000)
      },
      immediate: true
    }
  }
})
