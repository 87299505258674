<template>
  <d-form
    v-model="valid"
    @onSubmit="$emit('onSubmit')"
    @onCancel="
      $router.back()
      $emit('onCancel')
    "
    includeCancel
    v-bind="{ error, status }"
    dataCy="cannon-form"
  >
    <v-card flat>
      <sub-header label="General Parameters" />
      <v-card-text class="p0">
        <v-select
          v-if="showInstallationSelector"
          label="Installation"
          :items="sentries"
          item-text="name"
          item-value="id"
          ref="installationSelect"
          v-model="sensor.sentry_id"
          :disabled="!authorizeEdit"
          @change="$_updatedSentry"
          :error="selectInstallationError"
          :suffix="selecInstallationSuffix"
        ></v-select>
        <v-text-field
          label="Name"
          autofocus
          ref="nameField"
          v-model.trim="sensor.name"
          :disabled="!authorizeEdit"
          :rules="[rules.required]"
          :error="selectInstallationError"
        />
        <v-text-field
          label="Serial Number"
          v-model.trim="sensor.serial_number"
          v-if="!activePlan"
          :disabled="!authorizeEdit"
          :rules="[rules.required]"
        />
        <d-param-slider
          v-if="!activePlan"
          label="Direction Offset"
          v-model.number="sensor.direction_offset"
          :unit="getDegreeUnit"
          max="359"
          step="1"
          :disabled="!authorizeEdit"
          :absolute-value="$_absoluteDirection"
        />
        <d-param-slider
          label="Reach"
          v-model="sensor.reach"
          :min="reachParams.min"
          :max="reachParams.max"
          :step="reachParams.step"
          :disabled="!authorizeEdit"
          :unit="convertUnitIfImperial(userSettings, 'm')"
          data-cy="reach"
        />
        <d-param-slider
          v-if="activePlan"
          label="Direction"
          v-model="sensor.direction"
          :unit="getDegreeUnit"
          max="359.9"
          step="0.1"
          :disabled="$_hasCompass || !authorizeEdit"
          data-cy="direction"
        />
          <v-tooltip
            v-if="!activePlan"
            :open-delay="tooltipDelay"
            color="sdark"
            max-width="430"
            right
          >
        <d-param-slider v-if="!activePlan"
          slot="activator"
          label="Disruption Duration"
          max="300"
          v-model.number="sensor.shutoff_time"
          :disabled="!authorizeEdit"
          unit="s"
        />
          <span>
            Time in seconds disruption will be active
          </span>
          </v-tooltip>
      </v-card-text>
    </v-card>
  </d-form>
</template>

<script>
import SubHeader from '../SubHeader'
import { FormMixin, SensorMixin } from '@/components/Mixins'
import { mapGetters } from 'vuex'
import {
  convertValIfImperial,
  convertUnitIfImperial
} from '@/store/utils/index'

const props = {
  sensor: {
    type: Object
  },
  sentries: {
    type: Array,
    default: () => []
  },
  authorizeEdit: {
    type: Boolean,
    default: true
  },
  sensorTypes: {
    type: Array,
    default: () => [{ value: 'cannon', text: 'DroneCannon' }]
  },
  showInstallationSelector: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'cannon-form',
  props,
  components: {
    SubHeader
  },
  mixins: [FormMixin, SensorMixin],
  data: () => ({
    valid: false,
    selectInstallationError: true,
    selecInstallationSuffix: 'Please select',
    cannonTypes: [
      { text: '3 Bands', value: '3B' },
      { text: '5 Bands', value: '5B' },
      { text: '5 Bands MKII', value: '5B-MK2' }
    ]
  }),
  mounted() {
    this.$_updateOffset()
    this.$_initName()

    this.sensor.reach = this.convertValIfImperial(
      this.userSettings,
      this.sensor.reach,
      'm'
    )

    let selectedVal = this.$refs.installationSelect.$props.value
    if (selectedVal === null) {
      console.log('no installation selected')
    } else {
      console.log(this.$refs.installationSelect.$props.value)
      this.selectInstallationError = false
      this.selecInstallationSuffix = ''
    }
  },
  computed: {
    ...mapGetters('plans', ['activePlan']),
    ...mapGetters('users', ['getDegreeUnit', 'user']),
    userSettings() {
      return this.user.settings
    },
    reachParams() {
      return {
        reach: this.convertValIfImperial(this.userSettings, 4000, 'm'),
        min: this.convertValIfImperial(
          this.userSettings,
          this.activePlan ? 1500 : 100,
          'm'
        ),
        max: this.convertValIfImperial(
          this.userSettings,
          this.activePlan ? 2000 : 5000,
          'm'
        ),
        step: this.convertValIfImperial(this.userSettings, 200, 'm')
      }
    }
  },
  methods: {
    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial
  }
}
</script>
